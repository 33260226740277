import React, { useEffect, FC, useState, useCallback, useRef } from "react";
import { Checkbox, Tree } from "antd";
import { LineIcon } from "components/ailiIcon";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { TreeProps } from "antd/es/tree";
import { isEqual } from "lodash-es";
import { useIntl } from "react-intl";
import { monitorTreeStore } from "store";
import { observer } from "mobx-react";
import Menu from "./menu";

export interface TreeNodeProps {
  /** 节点id */
  id?: string;
  /** 节点id，树节点需要 */
  key: string;
  /** 节点类型*/
  type?: number;
  /** 节点文字 */
  title?: string | JSX.Element;
  depName?: string;
  name?: string;
  /** 总车辆数 */
  count?: number;
  /** 在线车辆数 */
  online?: number;
  /** 状态 */
  status?: number;
  /** 是否可以勾选 */
  checkable?: boolean;
  icon?: JSX.Element;
  switcherIcon?: JSX.Element;
  selectable?: boolean;
  disabled?: boolean;
  plateNo?: string;
  runStatus?: string;
  videoChannel?: string;
  vehicleId?: string;
  /** 通道号 */
  passageway?: string;
  vehicleDtos?: TreeNodeProps[];
  childs?: TreeNodeProps[];
  departmentDtos?: TreeNodeProps[];
  children?: TreeNodeProps[];
}

interface BaseTreeProps extends Omit<TreeProps, "treeData"> {
  /** 车辆数类型， 1：全部； 2：在线； 3：离线 */
  type: 1 | 2 | 3;
}

const { TreeNode } = Tree;

const areEqual = (prevProps: BaseTreeProps, nextProps: BaseTreeProps) => {
  return isEqual(prevProps, nextProps);
};

const BaseTree: FC<BaseTreeProps> = React.memo(
  observer((props: BaseTreeProps) => {
    const { onSelect, type, onDoubleClick, ...extra } = props;
    const { formatMessage: f } = useIntl();
    const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
    const [check, setCheck] = useState<boolean>(false);
    const [treeData, setTreeData] = useState<any[]>([]);
    const single: any = useRef(null);

    useEffect(() => {
      setTreeData(
        handleItem(
          monitorTreeStore.vehicleTreeData,
          monitorTreeStore.vehicleName
        )
      );
    }, [monitorTreeStore.vehicleName, monitorTreeStore.vehicleTreeData]);

    useEffect(() => {
      // 获取当前车辆状态是否全选
      setCheck(monitorTreeStore.isCheckAll(props.type));
    }, [monitorTreeStore.vehicleChecks]);

    /** 车辆过滤条件 */
    const matcher = (item: any, keywords: string): boolean => {
      // 过滤车辆title和simNo
      let isShow = !!keywords.trim()
        ? item.title.includes(keywords.trim()) ||
          (item.simNo && item.simNo.includes(keywords.trim()))
        : true;

      // 过滤状态
      if (props.type === 2) {
        isShow = !!item.online && isShow;
      }

      if (props.type === 3) {
        isShow = !item.online && isShow;
      }
      return isShow;
    };

    /** 过滤节点 */
    const filterNode = (children: any[] = [], keywords: string): boolean => {
      return (
        children &&
        !!children.length &&
        children.some((item) => {
          if (item.type === 2) {
            return matcher(item, keywords);
          } else {
            // 处理企业的children，企业没有车辆数据，就隐藏
            return item.children && item.children.length
              ? filterNode(item.children, keywords)
              : false;
          }
        })
      );
    };

    const handleItem = (data: any[], keywords: string = "") => {
      return data.map((item) => {
        const { children, key, title, ...extra } = item;
        if (children && children.length) {
          // 过滤关键字
          let isShow = !!keywords;
          if (item.type === 2) {
            isShow = matcher(item, keywords);
          } else {
            isShow = filterNode(children, keywords.trim());
          }

          if (isShow && keywords) {
            // 展开该节点
            monitorTreeStore.addVehicleExpand(key);
          }

          return isShow ? (
            <TreeNode
              key={key}
              title={handleTreeTitle(title, item.type, item)}
              icon={item.type === 0 || item.type === 1 ? <LineIcon /> : null}
              {...extra}
            >
              {isShow ? handleItem(children, keywords) : null}
            </TreeNode>
          ) : null;
        } else {
          let isShow = true;
          if (item.type === 2) {
            // 无通道的车辆
            isShow = matcher(item, keywords);
          } else {
            // 没有车辆的车组或者企业
            isShow = item.type === 99;
          }
          return isShow ? (
            <TreeNode
              key={key}
              title={handleTreeTitle(title, item.type, item)}
              icon={item.type === 2 || item.type === 3 ? <LineIcon /> : null}
              {...extra}
              switcherIcon={
                item.type === 99 ? <LineIcon type={item.switcherIcon} /> : null
              }
              checkable={item.type !== 99}
            />
          ) : null;
        }
      });
    };

    const handleTreeTitle = (title: string, type: number, item: any) => {
      //TODO1.5添加指令
      if (type === 2) {
        let icon = "gray"; // 车辆图标
        if (item.online) {
          if (item.isAlarm) {
            icon = "red";
          } else if (item.isRun) {
            icon = "green";
          } else if (!item.isRun) {
            icon = "blue";
          }
        }
        return (
          <div>
            <img
              src={`/images/monitor/${icon}.png`}
              alt=""
              width={10}
              style={{
                width: 10,
                height: 20,
                verticalAlign: "sub",
                marginRight: 4,
              }}
            />
            <span>{title} </span>
            <Menu record={item} />
          </div>
        );
      } else {
        return title;
      }
    };

    /**
     * 全部选择按钮
     * @param e
     */
    const checkAll = (e: CheckboxChangeEvent) => {
      if (treeData && treeData.length) {
        // 展开属性结构
        if (e.target.checked) {
          monitorTreeStore.checkAllVehicle(props.type);
        } else {
          monitorTreeStore.cancelCheckVehicle(props.type);
        }
      }
    };

    // 选择
    function selfSelect(keys: string[], node: any) {
      if (single.current) {
        (window as any).clearTimeout(single.current);
        single.current = null;
        return;
      }
      single.current = setTimeout(() => {
        if (onSelect && node.node.type === 2) {
          onSelect(keys, node);
        }
        single.current = null;
      }, 300);
    }

    function selfDoubleClick(e: any, node: any) {
      if (onDoubleClick) {
        onDoubleClick(e, node);
      }
    }

    // 勾选
    const onCheck = useCallback((keys: string[], node: any) => {
      const _keys = node.checkedNodes
        .filter((item: any) => item.type === 2)
        .map((item: any) => item.key);
      if (_keys.length) {
        monitorTreeStore.checkVehicle(_keys, props.type);
      } else {
        monitorTreeStore.cancelCheckVehicle(props.type);
      }
      if (props.onCheck) {
        props.onCheck(keys, node);
      }
    }, []);

    function onExpand(keys: string[]) {
      setExpandedKeys(keys);
    }

    return (
      <div className="monitor-tree-tabPane-content">
        <div className="monitor-tabPane-all-check">
          <Checkbox checked={check} onChange={checkAll}>
            {f({ id: "tx000025", description: "选择全部" })}
          </Checkbox>
        </div>
        <div className="monitor-base-tree">
          {treeData && treeData.length ? (
            <Tree
              checkable
              {...extra}
              blockNode
              onSelect={selfSelect}
              onDoubleClick={selfDoubleClick}
              checkedKeys={monitorTreeStore.vehicleChecks}
              onCheck={onCheck}
              onExpand={onExpand}
              defaultExpandAll={true}
            >
              {treeData}
            </Tree>
          ) : null}
        </div>
        <div className="monitor-icon">
          <div>
            {f({ id: "tx010104", description: "行驶" })}
            <img src="/images/monitor/green.png" alt="" />
          </div>
          <div>
            {f({ id: "tx010105", description: "停车" })}
            <img src="/images/monitor/blue.png" alt="" />
          </div>
          <div>
            {f({ id: "tx010106", description: "报警" })}
            <img src="/images/monitor/red.png" alt="" />
          </div>
          <div>
            {f({ id: "tx010103", description: "离线" })}
            <img src="/images/monitor/gray.png" alt="" />
          </div>
        </div>
      </div>
    );
  }),
  areEqual
);

export default BaseTree;
