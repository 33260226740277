import React, { useState, useEffect, useCallback } from 'react'
import { Spin, Tree, Result, message, Button } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import { MaxCheck } from 'util/comm'
import { monitorTreeStore } from 'store';
import { observer } from 'mobx-react'
import classnames from 'classnames'

interface GroupTreeProps {
  onCancel?: (value: boolean) => void
  onOk?: (keys: Array<{ key: string, title: string }>) => void
  /** 是否需要底部按钮 */
  isButton?: boolean,
  [key: string]: any,
  className?: string
  treeClassName?: string
}

export interface CheckItem {
  title: string,
  id: string
  key: string
}

interface GroupTreeItems {
  icon: JSX.Element
  key: string
  id: string
  children?: GroupTreeItems[]
  name: string
}

const { TreeNode } = Tree

const GroupTree = (props: GroupTreeProps) => {

  const { onCancel, onOk, isButton, className, treeClassName, ...extra } = props

  const [treeData, setTreeData] = useState<any[]>([])

  const { formatMessage: f } = useIntl()

  useEffect(() => {
    // 清除展开
    monitorTreeStore.cleanCompanyExpand()
    if (monitorTreeStore.companyName) {
      // 过滤树
      setTreeData(handleItem(monitorTreeStore.companyTreeData, monitorTreeStore.companyName))
    } else {
      monitorTreeStore.queryCompanyData()
    }
  }, [monitorTreeStore.companyName])

  useEffect(() => {
    setTreeData(handleItem(monitorTreeStore.companyTreeData))
  }, [monitorTreeStore.companyTreeData])


  const onCheck = (_checkedKeys: Array<string | number> | any, obj: any) => {

    const { checkedNodes } = obj
    const MAX: number = 20 // 最大选取数
    // 过滤父级节点
    let items: any[] = checkedNodes

    if (items.length > MAX) {
      message.warning(f({ id: 'tx000210', description: '最多勾选20个' }, { value: MAX }))
      items = MaxCheck(monitorTreeStore.companyCheckArr, items, MAX)
    }
    monitorTreeStore.updateCompanyCheck(items.map((item: any) => item.key))
    monitorTreeStore.updateCompanyCheckNames(items)
  }

  const matcher = (title: string, keywords: string) => {
    return title.trim().toLowerCase().indexOf(keywords.trim().toLowerCase()) !== -1
  }

  /** 过滤节点 */
  const filterNode = (children: GroupTreeItems[] = [], keywords: string): boolean => {
    return children && !!children.length && !!children.find(item => {
      return matcher(item.name, keywords.trim()) || filterNode(item.children, keywords)
    })
  }

  const handleItem = (data: GroupTreeItems[], keywords: string = '') => {

    return data.map(item => {
      const { children, id, name } = item

      if (children) {

        let isShow = keywords ? name.indexOf(keywords) === -1 ? false : true : true
        let isShowChild = keywords ? filterNode(children, keywords) : true
        if (isShowChild && keywords) {
          // 展开该节点
          monitorTreeStore.addCompanyExpand(id)
        }
        return (
          <TreeNode
            style={ { display: isShow || isShowChild ? 'flex' : 'none' } }
            key={ id }
            title={ name }
          >
            { handleItem(children, keywords) }
          </TreeNode>
        )
      } else {
        return (
          <TreeNode
            style={ { display: name.indexOf(keywords) === -1 ? 'none' : 'flex' } }
            key={ id }
            title={ name } />
        )
      }
    })
  }

  const onExpand = (keys: string[]) => {
    monitorTreeStore.updateCompanyExpand(keys)
  }

  const okEvent = useCallback(() => {
    if (onOk) {
      onOk(monitorTreeStore.companyCheckArr)
    }
  }, [monitorTreeStore.companyCheckArr])

  const cancelEvent = () => {
    if (onCancel) {
      onCancel(false)
    }
  }

  return (
    <div className={ classnames('group-tree-content', className) } >
      <div className='group-tree'>
        {
          monitorTreeStore.companyLoading ?
            <div className='group-tree-loading'>
              <Spin size='large' />
            </div>
            :
            treeData.length ?
              <Tree
                checkable
                showIcon
                checkStrictly={ true }
                selectable={ false }
                checkedKeys={ monitorTreeStore.companyChecks }
                onCheck={ onCheck }
                expandedKeys={ monitorTreeStore.companyExpandedKeys }
                onExpand={ onExpand }
                className={ treeClassName }
                { ...extra }
              >
                { treeData }
              </Tree>
              :
              <Result
                style={ {
                  width: '100%'
                } }
                icon={ <InboxOutlined /> }
                title={
                  <span style={ { fontSize: 16 } }>
                    { f({ id: 'tx000107', description: '暂无数据' }) }
                  </span>
                }
              />
        }
      </div>
      {
        props.isButton !== false ?
          <div className='group-tree-tool'>
            <Button type='primary' onClick={ okEvent }> 确定 </Button>
            <Button onClick={ cancelEvent }> 取消 </Button>
          </div>
          : null
      }
    </div>
  )
}

export default observer(GroupTree)
