import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import CameraPlayer from "./player";

import initTalk from "./player/talk_sdk";
import "./player/style.css";
import classnames from "classnames";
import { baseProps } from "./interface";
import moment from "moment";

const wasmFilePath = window.location.origin + "/libffmpeg_264_265.js"; //必须是绝对路径

/**
 * 对原生video标签的封装，暴露play pause clean三个方法和video标签对象到外部
 * @param props {baseProps}
 * @param ref
 * @constructor
 */
const Video = (props: baseProps, ref: any) => {
  const playerRef = useRef<any>(null);

  const talkRef = useRef<any>(null);
  // {
  //   uid: "ZT001010700153116",
  //   channelCode: 0,
  //   md5: "56aa3238b272f4295e020359eb47f741",
  //   softDecoding: false,
  // }
  const [info, setInfo] = useState<any>(null);

  const reStartTime = useRef<number>(0);

  const pollingRestartRef = useRef<any>();

  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    initFun();
    return () => {
      if (props.playbackType === "voiceIntercom") {
        if (talkRef.current) {
          talkRef.current?.stopTalk();
        }
      } else {
        if (playerRef.current) {
          playerRef.current?.stopPlay();
        }
      }
    };
  }, [props.url]);

  function initFun(isLoading = true) {
    if (props.url) {
      initPlayer(props.url, isLoading);
    } else {
      destroy();
    }
  }

  useEffect(() => {
    if (props.onPlayTimeChange) {
      props.onPlayTimeChange(currentTime);
    }
  }, [currentTime]);

  useEffect(() => {
    voiceChange(props.voice);
  }, [props.voice]);

  useImperativeHandle(ref, () => {
    return {
      play: play,
      pause: pause,
      clean: cleanChunnk,
      screenCapture: screenCapture,
    };
  });

  /**
   * 初始化播放器
   * 根据props.type判断采用flv.js还是用原生video标签播放
   * @param selfurl
   */
  const initPlayer = async (selfurl: string, _isLoading = true) => {
    await destroyEvent();
    if (props.type === "flv") {
      //初始化
      playerInit(selfurl);
    } else {
      // let _urlarr = url ? url.split("t=") : "";
      // let _selfurlarr = selfurl ? selfurl.split("t=") : "";
      // if (_urlarr && _urlarr[0] === _selfurlarr[0]) {
      //   videoElement.current.currentTime = 0;
      //   videoElement.current.play();
      // } else {
      //   setUrl(selfurl || "");
      // }
    }
  };

  function GetQueryString(url: string, name: string, default_value: any) {
    //获取超链接数据
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    let uirval = url.split("?");
    var r = uirval[1].substr(0).match(reg);
    if (r != null) return r[2];
    return default_value;
  }

  function playerInit(selfurl: string) {
    if (selfurl) {
      let nInfo = {
        // uid: "3030303030300",
        uid: GetQueryString(selfurl, "uid", null),
        username: GetQueryString(selfurl, "username", null),
        password: GetQueryString(selfurl, "password", null),
        turnServerUrl: selfurl.split("?")[0],
        bitstream: 0,
        softDecoding: true,
      };

      if (props.onInstanceCreated) {
        props.onInstanceCreated();
      }
      reStartTime.current = 1;
      //对讲初始化
      if (props.playbackType === "voiceIntercom") {
        //语音对讲
        setInfo(null);
        let talkInfo = {
          // uid: "3030303030300",
          devid: GetQueryString(selfurl, "uid", null),
          username: GetQueryString(selfurl, "username", null),
          password: GetQueryString(selfurl, "password", null),
          httpURL: selfurl.split("?")[0],
        };
        talkRef.current = new initTalk(talkInfo);
      } else {
        //视频播放
        setInfo((_pre: any) => nInfo);
      }

      // 语音对讲
      if (props.playbackType === "voiceIntercom") {
        setTimeout(() => {
          if (talkRef.current) {
            talkRef.current
              .startTalk()
              .then(() => {
                reStartTime.current = 1;
                if (props.onStart) {
                  props.onStart();
                }
              })
              .catch((err: string) => {
                console.log(err, "语音对讲startTalk+error");
                // //重新startPlay
                reStart();
              });
          }
        }, 1000);
      } else {
        setTimeout(() => {
          if (playerRef.current) {
            // 视频播放
            playerRef.current
              .initPlay()
              .then(() => {
                reStartTime.current = 1;
              })
              .catch((err: string) => {
                console.log(err, "视频initPlay+error");
                //重新startPlay
                reStart();
              });
          }
        }, 1000);
      }
    }
  }
  //重新连接 超过三次停止
  function reStart() {
    if (reStartTime.current > 40) {
      return;
    }
    reStartTime.current = reStartTime.current + 1;

    // 语音对讲
    if (props.playbackType === "voiceIntercom") {
      pollingRestartRef.current = setTimeout(() => {
        if (talkRef.current) {
          talkRef.current
            .startTalk()
            .then(() => {
              reStartTime.current = 1;
              if (props.onStart) {
                props.onStart();
              }
            })
            .catch((err: string) => {
              console.log(err, "语音对讲startTalk+error");
              //重新startPlay
              reStart();
            });
        }
      }, 500);
    } else {
      pollingRestartRef.current = setTimeout(() => {
        if (playerRef.current) {
          playerRef.current
            .initPlay()
            .then(() => {
              reStartTime.current = 1;
            })
            .catch((err: string) => {
              console.log(err, "视频initPlay+error");
              //重新startPlay
              reStart();
            });
        }
      }, 500);
    }
  }

  const play = () => {
    if (props.onStart) {
      props.onStart();
    }
    palyEvent();
  };

  const ended = () => {
    if (props.onEnded) {
      props.onEnded();
    }
  };

  //暂停
  const pause = () => {
    if (props.playbackType === "voiceIntercom") {
      if (talkRef.current) {
        talkRef.current?.stopTalk();
      }
    } else {
      if (playerRef.current) {
        playerRef.current?.stopPlay();
      }
    }
  };

  const destroy = () => {
    // if (props.onDestroy) {
    //     props.onDestroy()
    // }
    destroyEvent();
  };

  const cleanChunnk = () => {
    // if (RTCPlayer.current) {
    //   let end = RTCPlayer.current.buffered.end(0);
    //   RTCPlayer.current.currentTime = end - 0.2;
    // }
  };
  //音量调整
  const voiceChange = (data: any) => {
    if (playerRef.current) {
      playerRef.current?.volumeControl(data);
    }
  };
  // 截图
  const screenCapture = (filename = "") => {
    if (playerRef.current) {
      playerRef.current?.screenshot(filename);
    }
  };
  const palyEvent = () => {};

  const destroyEvent = () => {
    if (props.playbackType === "voiceIntercom") {
      if (talkRef.current) {
        talkRef.current?.stopTalk();
      }
    } else {
      if (playerRef.current) {
        playerRef.current?.stopPlay();
      }
    }

    if (pollingRestartRef.current) {
      clearTimeout(pollingRestartRef.current);
    }
  };

  function onCanPlay() {
    if (props.onStart) {
      props.onStart();
    }
  }
  return (
    <>
      <CameraPlayer
        ref={playerRef}
        muted={false}
        onCanPlay={onCanPlay}
        wasmFilePath={wasmFilePath}
        onError={(err: any) => {
          console.log("+++播放错误", err);
        }}
        ontimeupdate={(currentTime: number) => {
          setCurrentTime(~~currentTime);
        }}
        {...info}
      />
    </>
  );
};

export default forwardRef(Video);
