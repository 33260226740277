import React, { useState, useEffect } from 'react'
import { Popover } from 'antd'
import { BarsOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import {
  VehicleInfo,
  Dispatch,
  LocationTracking,
  VideoDownload,
  AudioMonitoring,
  VoiceIntercom
} from './rightCommand'
import { monitorStore } from 'store'
import { observer } from 'mobx-react'
import { isDevelopment } from 'util/comm'

export interface MenuProps {
  record: any
}

function Menu(props: MenuProps) {
  const [visible, setVisible] = useState(false)
  const { formatMessage: f } = useIntl()

  useEffect(() => {
    if (visible) {
      document.onclick = () => {
        setVisible(false)
      }
    } else {
      document.onclick = null
    }
  }, [visible])

  const content = (
    <div className="vehicle-menu">
      <div className="item" onClick={e => itemClick(e, 1)}>
        {f({ id: 'tx000014', description: '位置追踪' })}
      </div>
      {isDevelopment() && (
        <>
          <div className="item" onClick={e => itemClick(e, 2)}>
            {f({ id: 'tx000015', description: '音频监听' })}
          </div>
          <div className="item" onClick={e => itemClick(e, 3)}>
            {f({ id: 'tx000016', description: '语音对话' })}
          </div>
        </>
      )}
      <div className="item" onClick={e => itemClick(e, 4)}>
        {f({ id: 'tx010021', description: '车辆信息' })}
      </div>
      <div className="item" onClick={e => itemClick(e, 5)}>
        {f({ id: 'tx000017', description: '调度下发' })}
      </div>
      <div className="item" onClick={e => itemClick(e, 6)}>
        {f({ id: 'tx1900302', description: '视频下载' })}
      </div>
      <div className="item" onClick={e => itemClick(e, 7)}>
        {f({ id: 'tx000067', description: '语音对讲' })}
      </div>
    </div>
  )

  const itemClick = (e: any, type: number) => {
    e.stopPropagation()
    monitorStore.showRightModal()
    monitorStore.updateRightModalInfo(props.record)
    switch (type) {
      case 1: // 位置追踪
        monitorStore.updateRightModal(LocationTracking)
        break
      case 2: // 音频监听
        if (isDevelopment()) {
          monitorStore.updateRightModal(AudioMonitoring)
        }
        break
      case 3: // 语音对话
        break
      case 4: // 车辆信息
        monitorStore.updateRightModal(VehicleInfo)
        break
      case 5: // 调度下发
        monitorStore.updateRightModal(Dispatch)
        break
      case 6: // 视频下载
        monitorStore.updateRightModal(VideoDownload)
        break
        case 7: // 语音对讲
        monitorStore.updateRightModal(VoiceIntercom)
        break
      default:
        throw new Error('右键指令选择错误')
    }
  }

  return (
    <span onClick={e => e.stopPropagation()}>
      <Popover
        overlayClassName="vehicle-popover"
        content={content}
        trigger="click"
        placement="rightTop"
        color="pink"
        visible={visible}
        onVisibleChange={setVisible}
      >
        <BarsOutlined onClick={e => e.stopPropagation()} />
      </Popover>
    </span>
  )
}

export default observer(Menu)
