/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-09-09 11:33:52
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-10-26 11:24:01
 */
import SockJS from 'sockjs-client'
import { over, Client } from 'stompjs'
import { getLocal, getLocalJson } from 'util/comm'
import { isString } from 'lodash-es'

interface SockProps {
  sendUrl: string
  messageUrl: string
  onCreated?: () => void
}

export default class Sock {

  socket: any
  stompClient: Client
  sendUrl: string
  messageUrl: string
  onCreated: undefined | (() => void)

  constructor(options: SockProps) {
    this.sendUrl = options.sendUrl
    this.messageUrl = options.messageUrl
    this.onCreated = options.onCreated
    this.initSock = this.initSock.bind(this)
    this.disconnect = this.disconnect.bind(this)
    this.sendMessage = this.sendMessage.bind(this)
  }

  initSock<T>(callback: (data: T) => void) {
    const token = getLocal('tx_token')
    const lang = getLocal('lang')
    const userInfo: any = getLocalJson('tx_userInfo')
    const wsUrl = process.env.REACT_APP_BASEURL
    this.socket = new SockJS(`${wsUrl}/api/webapi/queueServer?token=${token}&lang=${lang}`)
    this.stompClient = over(this.socket)

    this.stompClient.connect({
      'Content-Type': 'application/json;charset=UTF-8',
      'Authorization': token
    }, (_frame: any) => {
      if (this.onCreated) {
        this.onCreated()
      }
      this.stompClient.subscribe(`/user/${userInfo.id}/${this.messageUrl}`, (response: any) => {
        const _ = response.body
        if (callback) {
          callback(JSON.parse(_))
        }
      }, {
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token
      })
    }, error => {
      console.log('连接失败【' + error + '】')
    })
    // 关闭打印
    this.stompClient.debug = (_str: any) => {

    }
  }

  disconnect() {
    if (this.stompClient) {
      this.stompClient?.disconnect(() => { })
    }
  }

  sendMessage(data: any) {
    const message = isString(data) ? data : JSON.stringify(data)
    if (this.stompClient) {
      try {
        this.stompClient.send(`${this.sendUrl}`, {}, message)
      } catch (error) {
        console.log(`JSON Error In Sock Class: ${error}`)
      }
    }
  }

}