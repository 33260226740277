import React, { useState } from 'react'
import { Dropdown } from 'antd';
import classnames from 'classnames'
import { useIntl } from 'react-intl';
import { Icon } from 'components';
import { positionStory } from 'store'
import { observer } from 'mobx-react';

function MapTool() {

  const { formatMessage: f } = useIntl()


  const menu = (
    <div className='tx-map-tool'>
      <div className={ classnames({ active: positionStory.mapTool === 'close' }) } onClick={ () => { mapToolClick('close') } }><Icon type='iconshou' /> { f({ id: 'tx300002' }) }</div>
      <div className={ classnames({ active: positionStory.mapTool === 'rectangle' }) } onClick={ () => { mapToolClick('rectangle') } }><Icon type='iconchakan' /> { f({ id: 'tx300003' }) }</div>
      <div className={ classnames({ active: positionStory.mapTool === 'Point' }) } onClick={ () => { mapToolClick('Point') } }><Icon type='icondianwei' /> { f({ id: 'tx300004' }) }</div>
      <div className={ classnames({ active: positionStory.mapTool === 'outin' }) } onClick={ () => { mapToolClick('outin') } }><Icon type='iconfangda' /> { f({ id: 'tx300005' }) }</div>
      <div className={ classnames({ active: positionStory.mapTool === 'out' }) } onClick={ () => { mapToolClick('out') } }><Icon type='iconsuoxiao' /> { f({ id: 'tx300006' }) }</div>
      <div className={ classnames({ active: positionStory.mapTool === 'ranging' }) } onClick={ () => { mapToolClick('ranging') } }><Icon type='iconzhichi' /> { f({ id: 'tx300007' }) }</div>
    </div>
  )

  const mapToolClick = (value: string) => {
    positionStory.updateMapTool(value)
  }

  return (
    <Dropdown
      arrow
      overlay={ menu }
      placement="bottomRight"
    >
      <span style={ {
        padding: 6
      } }>
        { f({ id: 'tx300001' }) }
        <Icon style={ { marginLeft: 10 } } type='iconxiesanjiao' />
      </span>
    </Dropdown>
  )
}

export default observer(MapTool)
