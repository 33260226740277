/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-11-16 21:55:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-27 16:19:56
 */
import React, { useEffect, useState } from 'react'
import { OlMap, OlMarker, OlInfoWindow } from 'components'
import { observer } from 'mobx-react'
import { broadcastStory, monitorStore, positionStory, userSettingStore } from 'store'
import InforWindowContent from '../components/inforWindowContent';
import { webAPIResponse } from 'server/web/index.globals';
import { MapTextStyle } from '../comm';
import { useInterval } from 'react-use';
import Broadcast from './video';

function Index() {

  const [time, setTime] = useState<number>(0)

  useInterval(() => {
    // 每隔20秒获取最新的车辆详情
    if (broadcastStory.vehicleInfo) {
      monitorStore.getVehiclesInfoAjax(broadcastStory.vehicleInfo.vehicleId, '3')
    }
  }, time ? 20000 : null)

  useEffect(() => {
    if (broadcastStory.vehicleInfo) {
      setTime(20000)
    } else {
      setTime(0)
    }
  }, [broadcastStory.vehicleInfo])

  const handleVehicleNo = (vehicle: webAPIResponse.vehiclesInfo) => {
    const type = userSettingStore.baseSetting.vehicleLabelType
    switch (type) {
      case '1': // 显示车牌+车队
        return vehicle.depName ? vehicle.plateNo + '-' + vehicle.depName : vehicle.plateNo;
      case '2': //显示车牌+simNo
        return vehicle.simNo ? vehicle.plateNo + '-' + vehicle.simNo : vehicle.plateNo;
      case '3': // 显示车牌+编号
        return vehicle.serialNo ? vehicle.plateNo + '-' + vehicle.serialNo : vehicle.plateNo
      default:
        return vehicle.plateNo;
    }
  }

  return (
    <div className='tx-broadcast'>
      <Broadcast />
      <div className='tx-broadcast-map'>
        <OlMap
          center={ broadcastStory.mapCenter ? [
            broadcastStory.mapCenter.lng,
            broadcastStory.mapCenter.lat
          ] : undefined }
          type={ positionStory.mapType }
          updateSize={ monitorStore.tabsKey }
        >
          {
            broadcastStory.vehicleInfo ?
              <OlMarker
                key={ broadcastStory.vehicleInfo.vehicleId + '_select' }
                icon={ {
                  src: broadcastStory.vehicleInfo.icon || '/images/monitor/gray.png',
                  // src: handleIcon(broadcastStory.vehicleInfo),
                  scale: 0.5,
                  size: [28, 28],
                } }
                label={ {
                  content: (<div style={ MapTextStyle }>{ handleVehicleNo(broadcastStory.vehicleInfo) }</div>),
                  offset: [-6, 5]
                } }
                position={ [broadcastStory.vehicleInfo.longitude, broadcastStory.vehicleInfo.latitude] }
                rotation={ broadcastStory.vehicleInfo.direction }
                onClick={ () => {
                  broadcastStory.updateInfoWindowVisible(true)
                } }

              />
              : null
          }
          <OlInfoWindow
            visible={ broadcastStory.infowindowVisible }
            position={ broadcastStory.vehicleInfo ?
              [broadcastStory.vehicleInfo.longitude, broadcastStory.vehicleInfo.latitude]
              : undefined
            }
            offset={ [0, -21] }
            autoMove={ false }
          >
            <InforWindowContent
              info={ broadcastStory.vehicleInfo }
              showTool={ false }
              onClose={ () => { broadcastStory.updateInfoWindowVisible(false) } }
            />
          </OlInfoWindow>
        </OlMap>
      </div>
    </div>
  )
}

export default observer(Index)
