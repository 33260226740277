/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-11-16 21:55:25
 * @LastEditors: 
 * @LastEditTime: 2021-01-26 14:13:26
 */
import React, { CSSProperties } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import './index.less'

interface ModalProps {
  title: string | JSX.Element
  /** 宽度 默认300 */
  width?: number
  /** 关闭回调事件 */
  onClose?: () => void
  header?: JSX.Element
  children: JSX.Element
  style?: CSSProperties
  bodyStyle?: CSSProperties
}

function Modal(props: ModalProps) {

  const onClose = () => {
    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <div className='tx-position-modal' style={ props.style ? props.style : {} }>
      <div className='position-modal-header'>
        <div className='position-modal-title'>
          { props.title }
        </div>
        <div>
          { props.header }
          <CloseOutlined className='position-modal-icon' onClick={ onClose } />
        </div>
      </div>
      <div className='position-modal-content' style={ props.bodyStyle ? props.bodyStyle : {} }>
        { props.children }
      </div>
    </div>
  )
}

export default Modal
