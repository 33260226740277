/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-11-16 21:55:25
 * @LastEditors: 
 * @LastEditTime: 2021-01-26 14:14:59
 */
import React from 'react'
import Modal from './modal'
import { Button, Empty } from 'antd';
import { useIntl } from 'react-intl'
import classnames from 'classnames'
import { useObserver } from 'mobx-react'
import monitorStore from 'store/monitor'
import './index.less'

interface ModalProps {
  onClose?: () => void
}

function VehicleModal(props: ModalProps) {
  const { formatMessage: f } = useIntl()

  return useObserver(() =>
    <Modal
      title={ f({ id: 'tx000136', description: '危险车辆提醒' }) }
      onClose={ props.onClose }

    >
      <div className={ classnames('d-vehicle-modal', { 'no-data': !monitorStore.dangerousVehiclesModal.length }) } >
        {
          monitorStore.dangerousVehiclesModal.length ?
            monitorStore.dangerousVehiclesModal.map((item: any, index: number) => {
              // TODO 去处理 页面跳转
              return <div className='d-vehicle-item' key={ 'vehicle_' + index }>
                <div className='d-vehicle-modal-serial'>[{ index + 1 }]</div>
                <div className='d-vehicle-modal-plateNo'>{ item.plateNo || '' }</div>
                <div className='d-vehicle-modal-department'>{ item.department || '' }</div>
                <div
                  className={
                    classnames(
                      'd-vehicle-modal-dangerLevel',
                      {
                        'type1': item.dangerLevel === '1',
                        'type2': item.dangerLevel === '2',
                        'type3': item.dangerLevel === '3',
                        'type4': item.dangerLevel === '4',
                      })
                  }
                >{ item.dangerLevelDesc || '' }</div>

                {/* <div><Button type='link' size='small'>{ f({ id: 'tx030009', description: '去处理' }) }</Button></div> */ }
              </div>
            })
            : <Empty />
        }
      </div>
    </Modal>
  )
}

export default VehicleModal
