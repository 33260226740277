import React, { useEffect, useRef } from 'react'
import Modal from './modal'
import { Empty, Pagination, Tooltip } from 'antd';
import { useIntl } from 'react-intl'
import { Info } from 'components';
import { InfoItems } from 'components/info'
import { UploadOutlined, AlertFilled } from '@ant-design/icons'
import useSetState from 'util/useSetState'
import { webAPIResponse } from 'server/web/index.globals';
import { positionStory } from 'store'
import { observer } from 'mobx-react'
interface ModalProps {
  onClose?: () => void
}

interface MyState {
  info: webAPIResponse.alarmAttachment | undefined
  page: number
  total: number
}

const baseUrl: string = process.env.REACT_APP_BASEURL!

function AbnormalModal(props: ModalProps) {
  const { formatMessage: f } = useIntl()
  const videoElement: any = useRef()

  const [state, setState] = useSetState<MyState>({
    info: undefined,
    page: 1,
    total: 1
  })

  useEffect(() => {
    return () => {
      if (videoElement.current) {
        videoElement.current.onended = null
      }
    }
  }, [])

  useEffect(() => {
    if (positionStory.abnormalData && positionStory.abnormalData.length) {
      setState({
        info: positionStory.abnormalData[0],
        total: positionStory.abnormalData.length,
        page: 1,
      })
    }
  }, [positionStory.abnormalData])

  useEffect(() => {
    // 监听视频播放，完成之后跳转下一条数据
    if (videoElement.current) {
      videoElement.current.onended = () => {
        const nextPage = state.page + 1
        if (nextPage <= state.total) {
          pageChange(state.page + 1)

        }
      }
    }
  }, [videoElement.current, state.page, state.total])

  const infoItem: InfoItems[] = [{
    children: [
      { dataIndex: 'plateNo', description: '车牌号' },
      {
        dataIndex: 'levelDesc', description: '报警等级', render: (value: string) => {
          const _value = value ? `${value} ${f({ id: 'tx000172' })}` : ''
          return <span>{ `${value ? _value : '-'}` }</span>
        }
      },
      { dataIndex: 'alarmTypeDesc', description: '报警类型' },
      { dataIndex: 'alarmTime', description: '报警时间' },
      {
        dataIndex: 'location', description: '报警地点',
        render: (value: any) => {
          return <Tooltip title={ value }>
            <div className='ellipsis-2'>
              { value }
            </div>
          </Tooltip>
        }
      },
    ]
  }]

  const pageChange = (page: number) => {
    setState({
      info: positionStory.abnormalData[page - 1],
      page
    })
  }

  return (
    <Modal
      title={ f({ id: 'tx020013', description: '报警视频' }) }
      onClose={ props.onClose }
    // header={
    //   <span>
    //     <UploadOutlined style={ { color: '#307CEC', marginRight: 6 } } />
    //     <AlertFilled style={ { color: '#CD262B', marginRight: 6 } } />
    //   </span>
    // }
    >
      <div className='alarm-video-modal'>
        <div className='alarm-video-modal-body' >
          <Info
            titleWidth={ 66 }
            items={ infoItem }
            data={ state.info }
            style={ {
              flex: '1'
            } }
          />
          <div className='alarm-video-modal-video'>
            {
              state.info && state.info.filePath ?
                <video
                  ref={ videoElement }
                  controls
                  autoPlay
                  style={ { width: '100%', height: '100%' } }
                  src={ state.info ? baseUrl + '/api/webapi/filenamager/getFileByPath?path=' + state.info.filePath : '' }>
                </video>
                : <div style={ { height: '100%', width: '100%', backgroundColor: '#888' } }> </div>
            }

          </div>
        </div>
        <Pagination
          style={ {
            width: '100%',
          } }
          size='small'
          showSizeChanger={ false }
          total={ state.total }
          pageSize={ 1 }
          current={ state.page }
          onChange={ pageChange }
        />

      </div>
    </Modal>
  )
}

export default observer(AbnormalModal)
