import React, { useEffect } from 'react'
import Modal from './modal'
import { useIntl } from 'react-intl'
import { Empty, Timeline } from 'antd'
import { useObserver } from 'mobx-react'
import monitorStore from 'store/monitor'
import positionStory from 'store/monitor/position'
import classnames from 'classnames'
import SuperiorCell from './superiorCell'

interface ModalProps {
  onClose?: () => void
}

function SuperiorModal(props: ModalProps) {
  const { formatMessage: f } = useIntl()

  useEffect(() => {
    // 测试数据
    // UserStore.superiorModal({ plateNo: '渝C2654D', message: '疲劳驾驶', type: 1 })
  }, [])

  return useObserver(() =>
    <Modal title={ f({ id: 'tx000135', description: '上级查岗' }) } onClose={ props.onClose }>
      <div className={ classnames('superior-modal', { 'no-data': !positionStory.superviseData.length }) } >
        {
          positionStory.superviseData.length ? (
            <div className='d-vehicle-item'>
              <Timeline>
                {
                  positionStory.superviseData.map((item: any) => {
                    return <SuperiorCell { ...item } />
                  })
                }
              </Timeline>
            </div>
          ) : <Empty />
        }
      </div>
    </Modal>
  )
}

export default SuperiorModal
