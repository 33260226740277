/*
 * @Description: 
 * @Author: 谢永红
 * @Date: 2020-11-16 21:55:25
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-01-26 14:27:39
 */
import React from 'react'
import { useObserver, observer } from 'mobx-react';
import { positionStory } from 'store';
import AbnormalModal from './modals/abnormalModal'
import RealtimeModal from './modals/realtimeModal'
import SuperiorModal from './modals/superiorModal'
import VehicleModal from './modals/vehicleModal'
import './tips.less'

interface TipProps {
  arr: number[]
}
function Tips(props: TipProps) {

  const onClose = (key: number) => {
    positionStory.deleteTips(key)
  }

  return useObserver(() =>
    <div className='tx-position-tips'>
      {
        props.arr && props.arr.map((item: number) => {
          if (item === 1) {
            return (<RealtimeModal key={ item } onClose={ () => { onClose(item) } } />) as any
          }
          if (item === 2) {
            return (<AbnormalModal key={ item } onClose={ () => { onClose(item) } } />) as any
          }
          if (item === 3) {
            return (<VehicleModal key={ item } onClose={ () => { onClose(item) } } />) as any
          }
          if (item === 4) {
            return (<SuperiorModal key={ item } onClose={ () => { onClose(item) } } />) as any
          }
        })
      }
    </div>
  )
}

export default observer(Tips)
