import React, {useState, memo} from "react"
import {Button, Form, Timeline, Input, message} from "antd"
import {SuperviseProps, replySupervise} from 'server/web/jt809'
import {useIntl} from "react-intl"
import {pleaseEnter as e} from "util/comm"

function SuperiorCell(props:SuperviseProps) {
  const { formatMessage: f } = useIntl()
  const {TextArea} = Input
  const [replyVisible, setReplyVisible] = useState(false)

  function replyToggle() {
    setReplyVisible(!replyVisible)
  }

  async function submitReply(values: any) {
    const result = await replySupervise({
      ...values,
      commandId: props.cmdId,
      infoId: props.infoId,
      objId: props.objId,
      objType: props.postQueryTypeDescr,
      platformId: props.platformId
    })
    if (result) {
      message.success(f({ id: 'tx000024', description: '操作成功' }));
    }
  }

  return (<Timeline.Item color={props.flag===1?'gray':'red'} key={props.cmdId}>
    <div>
      <div onClick={replyToggle}>
        <h3>{props.createDate}</h3>
        <div>{f({ id: 'tx400001', description: '查岗对象' })}:{props.objId}</div>
        <div>{f({ id: 'tx400002', description: '查岗类型' })}:{props.postQueryTypeDescr}</div>
        <div>{f({ id: 'tx400003', description: '查岗问题' })}:{props.content}</div>
      </div>
      {
        replyVisible && <div style={{marginTop: 10, marginRight: 10}}>
          <Form onFinish={submitReply}>
            <Form.Item
              name="answer"
              rules={[{required: true, message: e({ id: 'tx010001', description: '车牌号' })}]}>
              <TextArea placeholder={f({ id: 'tx400004', description: '回复' })}/>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType={'submit'} style={{float: 'right'}}>{f({ id: 'tx000012', description: '发送' })}</Button>
            </Form.Item>
          </Form>
        </div>
      }
    </div>
  </Timeline.Item>)
}

export default memo(SuperiorCell)