type EventListener = (eventData: any) => void;
type EventOff = () => void;

class EventBus {
  subscriptions: Map<string, EventListener[]>;
  constructor() {
    this.subscriptions = new Map();
  }

  on(eventType: string, listener: EventListener): EventOff {
    let listeners = this.subscriptions.get(eventType);
    if (!listeners) {
      listeners = [];
    }
    listeners.push(listener);
    this.subscriptions.set(eventType, listeners);
    return () => {
      this.off(eventType, listener);
    };
  }

  off(eventType: string, listener: EventListener) {
    let listeners = this.subscriptions.get(eventType);
    if (!listeners) {
      return;
    }
    let idx = listeners.findIndex((l) => l === listener);
    listeners.splice(idx, 1);
    this.subscriptions.set(eventType, listeners);
  }

  emit(eventType: string, eventData?: any) {
    let listeners = this.subscriptions.get(eventType);
    if (!listeners) {
      return;
    } else {
      listeners.forEach((fn) => fn(eventData));
    }
  }
}

export default new EventBus();
