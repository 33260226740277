import React from 'react'
import Modal from './modal'
import { useIntl } from 'react-intl'
import classnames from 'classnames'
import { DeleteOutlined } from '@ant-design/icons';
import { useObserver } from 'mobx-react'
import { Empty } from 'antd';
import { monitorStore } from 'store'
import './index.less'

interface ModalProps {
  onClose?: () => void
}

function RealtimeModal(props: ModalProps) {
  const { formatMessage: f } = useIntl()

  const cleanClick = () => {
    monitorStore.cleanRealTimeModal()
  }

  return useObserver(() =>
    <Modal
      title={ f({ id: 'tx020038', description: '实时报警信息' }) }
      onClose={ props.onClose }
      bodyStyle={ { paddingRight: 0 } }
      header={ <DeleteOutlined onClick={ cleanClick } style={ { color: '#656973', marginRight: 6 } } /> }
    >
      <div className={ classnames('realtime-modal', {
        'no-data': !monitorStore.realTimeModal.length
      }) }>
        {
          monitorStore.realTimeModal.length ? monitorStore.realTimeModal.map(item => {

            return <div style={ { color: item.textColor || '#333' } }>
              <span>{ item.plateNo } : </span>
              { item.alarmTypeName }
            </div>
          })
            :
            <Empty />
        }
      </div>
    </Modal>
  )
}

export default RealtimeModal
