import React, {
  useMemo,
  useReducer,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { Popover, Button, Input } from "antd";
import { Tabs, TabPane } from "components";

import { SearchOutlined, ClusterOutlined } from "@ant-design/icons";
import GroupTree, { CheckItem as GroupTreeCheckItem } from "./groupTree";
import BaseTree from "./baseTree";
import { getLangMessage, getLocalJson, urlSearchParams } from "util/comm";
import { isEqual } from "lodash-es";
import { webAPIResponse } from "server/web/index.globals";
import { getCheckedCompany } from "server/web/company";
import { monitorTreeStore } from "store";
import { observer } from "mobx-react";
import { useInterval } from "react-use";
import "./index.less";

interface PositionPageParams {
  plateNo: string;
}

interface TreeProps {
  onDoubleClick?: (e: React.MouseEvent, treeNode: any) => void;
  onSelect?: (keys: string[], treeNode: any) => void;
}

interface MyState {
  /** 车组名称显示 */
  crewsTakeValue: string;
  /** 车组ids */
  crewsTakeKeys: string;
  /** 是否显示车组弹窗 */
  groupVisible: boolean;
  countTree: webAPIResponse.VehicleTree[];
}

const areEqual = (prevProps: TreeProps, nextProps: TreeProps) => {
  return isEqual(prevProps, nextProps);
};

const tabBarStyle: React.CSSProperties = {
  height: ".45rem",
  margin: 0,
  overflow: "hidden",
  width: "100%",
};

const Tree: FC<TreeProps> = React.memo(
  observer((props: TreeProps) => {
    const { onDoubleClick, onSelect } = props;
    const { formatMessage: f } = useIntl();

    const [searchVal, setSearchVal] = useState<string>("");
    useInterval(() => {
      monitorTreeStore.pollqueryVehicle();
    }, monitorTreeStore.vehiclePollTime * 1000);

    // 递归树
    const foreachTree = (
      data: any[],
      callback: any,
      childrenName = "children"
    ) => {
      for (let i = 0; i < data.length; i++) {
        callback(data[i]);
        if (data[i][childrenName] && data[i][childrenName].length > 0) {
          foreachTree(data[i][childrenName], callback, childrenName);
        }
      }
    };
    const sleep = (delaytime = 100) => {
      return new Promise((resolve) => setTimeout(resolve, delaytime));
    };
    useEffect(() => {
      autoSelet();
    }, [monitorTreeStore.vehicleTreeData]);

    async function autoSelet() {
      if (
        monitorTreeStore?.vehicleTreeData &&
        monitorTreeStore?.vehicleTreeData?.length
      ) {
        const params = urlSearchParams<PositionPageParams>(
          decodeURIComponent(window.location.search)
        );
        let seletItem: any = null;
        if (params?.plateNo) {
          foreachTree(monitorTreeStore?.vehicleTreeData, (item: any) => {
            if (item.plateNo === params?.plateNo && item.type === 2) {
              seletItem = item;
            }
          });
        }
        if (seletItem) {
          await sleep();
          onSelect && onSelect([seletItem.key], { node: seletItem });
        }
      }
    }

    useEffect(() => {
      // 获取用户上次勾选企业
      getCheckedCompany().then((res) => {
        if (res && res.data) {
          if (JSON.stringify(res.data) !== "{}") {
            const keys: string[] = [];
            const _ = [];
            for (const key in res.data) {
              keys.push(key);
              _.push({
                title: res.data[key],
                key: key,
              });
            }
            GroupTreeCheck(_);
            monitorTreeStore.updateCompanyCheck(keys);
            monitorTreeStore.updateCompanyCheckNames(_);
          } else {
            // 获取用户上级企业
            const user: any = getLocalJson("tx_userInfo");
            if (user.orgId) {
              const _ = [
                {
                  title: user.orgName,
                  key: user.orgId,
                },
              ];
              GroupTreeCheck(_);
              monitorTreeStore.updateCompanyCheck([user.orgId]);
              monitorTreeStore.updateCompanyCheckNames(_);
            }
          }
        }
      });
    }, []);

    useEffect(() => {
      const params = urlSearchParams<PositionPageParams>(
        decodeURIComponent(window.location.search)
      );

      if (params?.plateNo) {
        setSearchVal(params?.plateNo ?? "");
        queryVehicleNo(params.plateNo);

        // triggerVal(params.plateNo);
      }
    }, []);

    const Reducers = useCallback((state: MyState, action: any) => {
      const { type, data } = action;
      switch (type) {
        case "updateCrewsTask":
          state.crewsTakeValue = data
            .map((item: GroupTreeCheckItem) => item.title)
            .toString();
          state.crewsTakeKeys = data
            .map((item: GroupTreeCheckItem) => item.key)
            .toString();
          monitorTreeStore.updateVehicleCompanyKeys(state.crewsTakeKeys);
          return { ...state };
        case "updateCompanyName":
          return { ...state };
        case "changeGroup":
          return { ...state, groupVisible: data };
        case "showGroupTree":
          return { ...state, groupTreeVisible: true };
        case "hideGroupTree":
          return { ...state, groupTreeVisible: false };
        case "updateVehicleStatusNumber":
          return { ...state, ...data };
        default:
          return state;
      }
    }, []);

    const [state, dispatch] = useReducer(Reducers, {
      crewsTakeValue: "",
      crewsTakeKeys: "",
      groupVisible: false,
    });

    const crewsTakeTitle: JSX.Element = (
      <div style={{ padding: "4px 0" }}>
        <Input.Search
          placeholder={getLangMessage(
            { id: "tx000202", description: "请输入企业" },
            { value: "tx130000" }
          )}
          onSearch={(value: string) => {
            monitorTreeStore.updateCompanyName(value);
          }}
        />
      </div>
    );

    /**
     * 企业勾选事件
     * @param data 勾选值
     */
    const GroupTreeCheck = (data: any[]) => {
      dispatch({ type: "changeGroup", data: false });
      dispatch({ type: "updateCrewsTask", data: data });
    };

    /** 隐藏企业弹窗 */
    const GroupTreeCancel = (value: boolean) => {
      dispatch({ type: "changeGroup", data: value });
    };

    /** 过滤车辆树 */
    const queryVehicleNo = (value: string) => {
      monitorTreeStore.updateVehicleName(value);
    };

    /** 显示隐藏企业 */
    const groupVisibleChange = (visible: boolean) => {
      dispatch({ type: "changeGroup", data: visible });
    };

    return (
      <div className="monitor-tree disable-select">
        <div className="tree-query-top">
          <span>{f({ id: "tx130000", description: "企业" })}</span>
          <div className="tree-query-top-input">
            <div
              className="ellipsis tree-query-top-title"
              style={{ color: state.crewsTakeValue ? "#22222" : "#999999" }}
              title={state.crewsTakeValue}
            >
              {state.crewsTakeValue ||
                getLangMessage(
                  { id: "tx000201", description: "请选择车组" },
                  { value: "tx130000" }
                )}
            </div>
            <div className="tree-query-icon">
              <SearchOutlined />
            </div>
          </div>
          <Popover
            placement="rightTop"
            trigger="click"
            title={crewsTakeTitle}
            content={useMemo(
              () => (
                <GroupTree onOk={GroupTreeCheck} onCancel={GroupTreeCancel} />
              ),
              []
            )}
            visible={state.groupVisible}
            onVisibleChange={groupVisibleChange}
          >
            <Button
              className={state.groupVisible ? "tree-query-btn-active" : ""}
            >
              <ClusterOutlined className="tree-query-btn-icon" />
            </Button>
          </Popover>
        </div>
        <div className="monitor-query">
          <Input.Search
            placeholder={getLangMessage(
              { id: "tx000204", description: "请输入车组/SIM卡号" },
              { value: "tx010001", value2: "tx060001" }
            )}
            value={searchVal}
            onSearch={queryVehicleNo}
            onChange={(e: any) => {
              setSearchVal(e.target.value);
            }}
          />
        </div>
        <Tabs
          defaultActiveKey="1"
          type="card"
          size="small"
          tabBarGutter={0}
          tabBarStyle={tabBarStyle}
          className="tree_n_more"
        >
          <TabPane
            className="monitor-tree-tabPane"
            tab={
              <div className="monitor-tree-tabPane-title">
                <span>{f({ id: "tx000109", description: "全部" })}</span>
                <span>({monitorTreeStore.vehicleCount})</span>
              </div>
            }
            key="1"
          >
            <BaseTree
              onDoubleClick={onDoubleClick}
              onSelect={onSelect}
              type={1}
            />
          </TabPane>
          <TabPane
            className="monitor-tree-tabPane"
            tab={
              <div className="monitor-tree-tabPane-title">
                <span>{f({ id: "tx010102", description: "在线" })}</span>
                <span>({monitorTreeStore.vehicleOnline})</span>
              </div>
            }
            key="2"
          >
            <BaseTree
              onDoubleClick={onDoubleClick}
              onSelect={onSelect}
              type={2}
            />
          </TabPane>
          <TabPane
            className="monitor-tree-tabPane"
            tab={
              <div className="monitor-tree-tabPane-title">
                <div>{f({ id: "tx010103", description: "离线" })}</div>
                <div>
                  (
                  {monitorTreeStore.vehicleCount -
                    monitorTreeStore.vehicleOnline}
                  )
                </div>
              </div>
            }
            key="3"
          >
            <BaseTree
              onDoubleClick={onDoubleClick}
              onSelect={onSelect}
              type={3}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }),
  areEqual
);

export default Tree;
