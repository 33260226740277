import React, { useCallback, useEffect, useRef, useState } from "react";
import { Tabs, TabPane, Access } from "components";
import { useIntl } from "react-intl";
import Broadcast from "./broadcast";
import Playback from "./playback";
import { urlSearchParams, setLocal } from "util/comm";
import AudioPlayback from "./audioPlayback";
import Trajectory from "./trajectory";
import TestDrive from "./testDrive";
import Position from "./position";

import Tree from "./monitorTree";
import MapTool from "./mapTool";
import MapChange from "./mapChange";
import {
  monitorStore,
  broadcastStory,
  positionStory,
  menuStore,
  intlStore,
  monitorTreeStore,
} from "store";
import { useLocation } from "react-router-dom";
import { TableDataProps } from "store/monitor";
import { useObserver } from "mobx-react";
import Sock from "util/sock";
import TipsModal from "./position/tips";
import EventBus from "util/EventBus";
import "./index.less";

interface MonitorProps {}

interface PositionPageParams {
  token: string;
  plateNo?: string;
}

const tabBarStyle: React.CSSProperties = {
  height: ".4rem",
  margin: 0,
  paddingLeft: 16,
  color: "#222",
  backgroundColor: "#fff",
};

function Index(_props: MonitorProps) {
  const location = useLocation();
  const { pathname } = location || {};

  const { formatMessage: f } = useIntl();
  const wbSocket: any = useRef();

  // tab标签权限控制
  const [tabPaneHTML, setTabPaneHTML] = useState<any>([]);
  const tabObject: any = {
    reallocation: {
      tabName: f({ id: "tx000112", description: "实时位置" }),
      tabHtml: <Position />,
      keyIndex: "1",
    },
    realvideo: {
      tabName: f({ id: "tx000114", description: "实时视频" }),
      tabHtml: <Broadcast />,
      keyIndex: "3",
    },

    videohistory: {
      tabName: f({ id: "tx000115", description: "视频回放" }),
      tabHtml: <Playback />,
      keyIndex: "4",
    },
  };

  useEffect(() => {
    const params = urlSearchParams<PositionPageParams>(
      decodeURIComponent(window.location.search)
    );
    intlStore.updateIntl("en_US");
    if (params.token) {
      setLocal("tx_token", params.token);
    }
  }, []);
  useEffect(() => {
    function getTabAccess() {
      let needList: string[] = [];
      Object.keys(tabObject).forEach((tt: string) => {
        needList.push(tt);
      });

      let htmlList: any[] = [];
      needList.forEach((dd: string) => {
        htmlList.push(
          <TabPane key={tabObject[dd].keyIndex} tab={tabObject[dd].tabName}>
            {tabObject[dd].tabHtml}
          </TabPane>
        );
      });
      setTabPaneHTML(htmlList);
    }
    getTabAccess();
  }, []);

  useEffect(() => {
    //重置选择tab标签
    monitorStore.updateTabsKey("1");
  }, [pathname]);
  useEffect(() => {
    wbSocket.current = new Sock({
      sendUrl: "/queueScreenMax",
      messageUrl: "/message",
      onCreated: () => {
        wbSocket.current.sendMessage("monitor");
      },
    });

    wbSocket.current.initSock((data: TableDataProps) => {
      positionStory.playAlarm();
      monitorStore.updateRealTimeModal(data);
    });

    //增加切到音频回放事件
    let off1 = EventBus.on("TabActiveChange", TabActiveChange);

    return () => {
      if (wbSocket.current) {
        wbSocket.current.disconnect();
      }
      off1();
    };
  }, []);

  /**
   * 树节点双击事件
   * @param data
   */
  const onDoubleClick = useCallback(
    (_e, treeNode) => {
      if (treeNode.type === 2 || treeNode.type === 99) {
        if (monitorStore.tabsKey === "1" || monitorStore.tabsKey === "3") {
          if (broadcastStory.videoList.length) {
            broadcastStory.closeVideo();
          }
          broadcastStory.doubleClickGetVideo({
            plateNo: treeNode.plateNo,
            simNo: treeNode.simNo,
            passway: treeNode.passway,
            vehicleId: treeNode.vehicleId,
          });
          monitorStore.getVehiclesInfoAjax(treeNode.vehicleId, "3");
        }
      }
    },
    [monitorStore.tabsKey]
  );

  /**
   * 树节点点击事件
   * @param data
   */
  const onSelect = useCallback(
    (_e, treeNode) => {
      const vehicleInfo = {
        plateNo: treeNode.node.plateNo,
        simNo: treeNode.node.simNo,
        passway: treeNode.node.passway,
        videoChannelDesc: treeNode.node.videoChannelDesc,
        vehicleId: treeNode.node.vehicleId,
      };

      if (treeNode)
        /**
         * 之前只有在case '2'和'4'的时候才调用updateVechileInfo，
         */
        monitorStore.updateVechileInfo(vehicleInfo);
      switch (monitorStore.tabsKey) {
        case "1":
          monitorStore.getVehiclesInfoAjax(treeNode.node.vehicleId, "1", true);
          return;
        case "2":
          monitorStore.getVehiclesInfoAjax(treeNode.node.vehicleId, "2");
          return;
        case "3":
          return;
        case "4":
          monitorStore.getVehiclesInfoAjax(treeNode.node.vehicleId, "4");
          return;

        case "5":
          monitorStore.getVehiclesInfoAjax(treeNode.node.vehicleId, "5");
          return;
        case "6":
          monitorStore.getVehiclesInfoAjax(treeNode.node.vehicleId, "6");
          return;
      }
    },
    [monitorStore.tabsKey]
  );

  function TabActiveChange() {
    tabsOnChange("6");
  }

  // 递归树
  const foreachTree = (
    data: any[],
    callback: any,
    childrenName = "children"
  ) => {
    for (let i = 0; i < data.length; i++) {
      callback(data[i]);
      if (data[i][childrenName] && data[i][childrenName].length > 0) {
        foreachTree(data[i][childrenName], callback, childrenName);
      }
    }
  };

  /**
   * tabs切换事件
   * @param activeKey
   */

  const tabsOnChange = (activeKey: string) => {
    // monitorStore.updateCloseVideoNumber()
    monitorStore.updateTabsKey(activeKey);
    /**
     * 之前下面这一行并没有被注释掉，
     * 导致在切换tabs的时候，存在monitorStore里的已选车辆信息丢失
     * 丢失之后在点击全部播放的时候无法判断是来自哪辆车
     */
    // monitorStore.updateVechileInfo({} as any)

    tirggleFun(activeKey);
  };

  const sleep = (delaytime = 100) => {
    return new Promise((resolve) => setTimeout(resolve, delaytime));
  };

  const tirggleFun = async (activeKey: string) => {
    if (
      monitorTreeStore?.vehicleTreeData &&
      monitorTreeStore?.vehicleTreeData?.length
    ) {
      const params = urlSearchParams<PositionPageParams>(
        decodeURIComponent(window.location.search)
      );
      let seletItem: any = null;
      if (params?.plateNo) {
        foreachTree(monitorTreeStore?.vehicleTreeData, (item: any) => {
          if (item.plateNo === params?.plateNo && item.type === 2) {
            seletItem = item;
          }
        });
      }
      if (seletItem) {
        await sleep();
        onSelect && onSelect([seletItem.key], { node: seletItem });
        if (activeKey === "3") {
          onDoubleClick && onDoubleClick(seletItem.key, seletItem);
        }
      }
    }
  };
  return useObserver(() => (
    <div className="tx-monitor">
      {/* 右下角Modal弹窗 */}
      {monitorStore.tabsKey === "1" ? (
        <TipsModal arr={positionStory.tipsArr} />
      ) : null}
      {/* 报警音频 */}
      <video
        ref={(element) => {
          positionStory.updateAlarmRef(element);
        }}
        style={{ width: 1, height: 1 }}
        src="/media/alarm.mp3"
      ></video>
      <Tree onDoubleClick={onDoubleClick} onSelect={onSelect} />
      {monitorStore.rightModal && (
        <monitorStore.rightModal
          record={monitorStore.rightModalRecord}
          visible={monitorStore.rightModalVisible}
          onCancel={() => {
            monitorStore.hideRightModal();
          }}
          cancelText={f({ id: "tx000053", description: "关闭" })}
          afterClose={() => {
            monitorStore.updateRightModal(null);
          }}
        />
      )}
      <div className="monitor-content tx-content">
        <Tabs
          className="monitor-tree-tab"
          defaultActiveKey={monitorStore.tabsKey}
          activeKey={monitorStore.tabsKey}
          tabBarStyle={tabBarStyle}
          onChange={tabsOnChange}
          tabBarGutter={16}
          destroyInactiveTabPane={true}
          style={{ width: "100%" }}
          tabBarExtraContent={
            <div style={{ paddingRight: 10 }}>
              {monitorStore.tabsKey === "1" ? <MapTool /> : null}
              <MapChange />
            </div>
          }
        >
          {tabPaneHTML}
        </Tabs>
      </div>
    </div>
  ));
}

export default Index;
