import React, { Key } from "react";
import { Dropdown, Menu } from "antd";
import { useIntl } from "react-intl";
import { Icon } from "components";
import { positionStory } from "store";
import { observer } from "mobx-react";
import { useBasicCodes } from "util/hook";
import { intlStore } from "store";

function MapChange() {
  const { formatMessage: f } = useIntl();

  const mapToolClick = (value: any) => {
    positionStory.updateMapTool("none");
    // positionStory.updateMapInfoByCache()
    //en_US状态下 type google 也指向amap
    positionStory.updateMapType(
      intlStore.lang === "en_US" ? "amap" : value.key
    );
  };

  const [{ data: basicCodes }] = useBasicCodes(["MapType"]);

  const menu = (
    <Menu selectedKeys={[positionStory.mapType]} onClick={mapToolClick}>
      {basicCodes?.MapType?.map((item) => (
        <Menu.Item key={item.key}>{item.text}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown arrow overlay={menu} placement="bottomRight">
      <span
        style={{
          padding: 6,
        }}
      >
        {f({
          id:
            positionStory.mapType === "amap"
              ? intlStore.lang === "en_US"
                ? "tx360003"
                : "tx360001"
              : "tx360002",
        })}
        <Icon style={{ marginLeft: 10 }} type="iconxiesanjiao" />
      </span>
    </Dropdown>
  );
}

export default observer(MapChange);
